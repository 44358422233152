<template>
  <div>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left" colspan="2">
              <h3>Informacción Acciones</h3>
            </th>
            <th class="text-right" colspan="3">
              <v-btn small dense color="secondary" v-on:click="addItem()"
                ><v-icon>mdi-plus</v-icon> Adicionar Accionista</v-btn
              >
            </th>
          </tr>
          <tr>
            <th class="text-center">Tipo Documento</th>
            <th class="text-center">Documento</th>
            <th class="text-center">Nombre</th>
            <th class="text-center">Porcentaje</th>
            <th class="text-center">Acciones</th>
          </tr>
        </thead>
        <tbody v-if="items.length > 0">
          <template v-for="item in items">
            <tr :key="item.id">
              <td>
                {{ item.type_document }}
              </td>
              <td class="text-center">
                {{ item.document_person }}
              </td>
              <td class="text-center">
                {{ item.name }}
              </td>

              <td class="text-center">
                {{ item.total }}
              </td>
              <td class="text-center">
                <v-btn icon v-on:click="deleteItem(item)"
                  ><v-icon>mdi-delete-forever</v-icon></v-btn
                >
                <v-btn icon v-on:click="editItem(item)"
                  ><v-icon>mdi-pencil</v-icon></v-btn
                >
              </td>
            </tr>
            <!--tr v-if="formAccion && formAccion.id == item.id">
                  <td colspan="5">
                    {{item}}
                  </td>
                  </tr-->
          </template>
        </tbody>
      </template>
    </v-simple-table>

    <v-dialog v-model="dialog" persistent max-width="600px">
      <ValidationObserver v-slot="{ invalid }" ref="form">
        <v-card style="background-color: white; z-index: 10000 !important">
          <v-card-title>
            <span class="headline">Información Accionista</span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <ng-select
                  v-model="formAccion.type_document"
                  label="person.tipo_documento"
                  url="api/v1/lists/document-type-person"
                  :rules="rules['tipo_documento']"
                  :dense="dense"
                  :outlined="outlined"
                  :filled="filled"
                ></ng-select>
              </v-col>
              <v-col cols="12">
                <ng-text
                  label="Documento"
                  :rules="rules['document_person']"
                  v-model="formAccion.document_person"
                ></ng-text>
              </v-col>
              <v-col cols="12">
                <ng-text
                  label="Nombre"
                  :rules="rules['name']"
                  v-model="formAccion.name"
                ></ng-text>
              </v-col>
              <v-col cols="12">
                <ng-number
                  label="% Acciones"
                  :rules="rules['total']"
                  v-model="formAccion.total"
                ></ng-number>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text v-on:click="cancelEdit()"
              >Cancelar</v-btn
            >
            <v-btn
              color="primary"
              :disabled="invalid"
              text
              v-on:click="saveItem()"
              >Guardar</v-btn
            >
          </v-card-actions>
        </v-card>
      </ValidationObserver>
    </v-dialog>

    <v-btn
      color="primary"
      class="mt-2"
      :disabled="!items || items.length == 0"
      @click="save"
    >
      {{ $t("Continuar") }}
    </v-btn>
  </div>
</template>
    <script>
import NgEmail from "@/components/inputs/NgEmail.vue";
import NgText from "@/components/inputs/NgText.vue";
import NgPhone from "@/components/inputs/NgPhone.vue";
import NgSelect from "@/components/inputs/NgSelect.vue";
import NgDate from "@/components/inputs/NgDate.vue";
import NgAuto from "@/components/inputs/NgAuto.vue";
import NgInteger from "@/components/inputs/NgInteger.vue";
import NgTextarea from "@/components/inputs/NgTextarea.vue";
import NgNumber from "@/components/inputs/NgNumber.vue";
//import NgCheckbox from "@/components/inputs/NgCheckbox.vue"
import NgSelectOptions from "@/components/inputs/NgSelectOptions.vue";
import NgMoney from "@/components/inputs/NgMoney.vue";
import NgSwitch from "@/components/inputs/NgSwitch.vue";

export default {
  name: "FormBasicData",
  components: {
    NgEmail,
    NgText,
    NgSwitch,
    NgPhone,
    NgSelect,
    NgDate,
    NgAuto,
    NgInteger,
    NgTextarea,
    NgNumber,
    NgSelectOptions,
    NgMoney,
  },
  props: {
    form: {
      type: Object,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    filled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    formAccion: {
      type_document: null,
    },
    dialog: false,
    items: [],
    rules: {
      type_document: { required: true },
      name: { required: true },
      document_person: { required: true },
      total: { required: true },
    },
  }),
  created() {
    if (this.form && this.form.info_acciones) {
      this.items = this.form.info_acciones;
    }
  },

  methods: {
    save() {
      let data = {
        id: this.form.id,
        info_acciones: this.items,
        //representante_tipo: this.form.empresa_representante_tipo_doc
      };


      this.$http
        .post2("api/v2/customer/" + this.form.id, data, true )
        .then((response) => {
          if (response.success) {
            this.$emit("next");
          }
        });
    },

    change() {
      //this.form["_dirty"] = true;
      //this.$emit("input:event:change");
    },

    deleteItem(item) {
      for (const i in this.items) {
        if (this.items[i].id == item.id) {
          this.items.splice(i, 1);
          break;
        }
      }
      //this.$forceUpdate();
      this.$emit("change:acciones", this.items);
      this.change();
    },
    cancelEdit() {
      this.formAccion = {};
      this.dialog = false;
    },
    editItem(item) {
      this.formAccion = Object.assign({}, item);
      this.oldItemValue = Object.assign({}, item);
      //Object.assign({}, item);
      this.dialog = true;
    },
    addItem() {
      this.formAccion = {
        id: new Date().getTime(),
        type_document: "cc",
        name: "",
        document_person: "",
        total: 50,
      };
      this.dialog = true;
    },
    saveItem() {
      let exists = false;
      if (!this.form.info_acciones) {
        this.form.info_acciones = [];
      }
      for (const i in this.items) {
        // console.log(this.formAccion.id, this.items[i].id);
        if (this.items[i].id == this.formAccion.id) {
          const item = Object.assign({}, this.formAccion);
          item.id = new Date().getTime();
          this.items[i] = item;
          //console.log(this.items[i])
          exists = true;
          break;
        }
      }
      if (!exists) {
        const item = Object.assign({}, this.formAccion);
        item.id = new Date().getTime();
        this.items.push(item);
      }
      this.formAccion = {
        id: "",
        type_document: "cc",
        name: "",
        document_person: "",
        total: 50,
      };
      this.dialog = false;
      //this.form[this.options.name] = this.items; //JSON.parse(JSON.stringify(this.items));
      this.$forceUpdate();
      //this.$emit("change:acciones", this.items);
      //this.change();
      //
    },
  },
};
</script>
<style>
.v-dialog {
  z-index: 10000 !important;
  background-color: white !important;
}
</style>