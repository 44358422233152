<template>
    <div>
      <ValidationObserver v-slot="{ invalid }" ref="form">
      <v-row  v-if="form" class="pt-4">
        <v-col cols="12" md="6" 
          ><ng-auto
            v-model="form.ciudad_residencia"
            label="person.ciudad_residencia"
            url="api/v1/lists/city"
            :rules="rules['ciudad_residencia']"
            :dense="dense"
            :outlined="outlined"
            :filled="filled"
          ></ng-auto
        ></v-col>

        <v-col cols="12" md="6" 
          ><ng-text
            v-model="form.barrio"
            label="person.barrio"
            :rules="rules['barrio']"
            :dense="dense"
            :outlined="outlined"
            :filled="filled"
          ></ng-text
        ></v-col>
        <v-col cols="12" md="6" 
          ><ng-text
            v-model="form.dir"
            label="person.dir"
            :rules="rules['dir']"
            :dense="dense"
            :outlined="outlined"
            :filled="filled"
          ></ng-text
        ></v-col>
        

        <v-col cols="12" md="6" 
          ><ng-phone
            v-model="form.celular"
            label="person.celular"
            :rules="rules['celular']"
            :dense="dense"
            :outlined="outlined"
            :filled="filled"
          ></ng-phone
        ></v-col>


        <v-col cols="12" md="6" 
          ><ng-phone
            v-model="form.telefono"
            label="person.telefono"
            :rules="rules['telefono']"
            :dense="dense"
            :outlined="outlined"
            :filled="filled"
          ></ng-phone
        ></v-col>

        <v-col cols="12" md="6" 
          ><ng-email
            v-model="form.correo"
            label="person.correo"
            :rules="rules['correo']"
            :dense="dense"
            :outlined="outlined"
            :filled="filled"
          ></ng-email
        ></v-col>
      </v-row>
      
  
      <v-btn color="primary" class="mt-2" :disabled="invalid" @click="save"> {{ $t("Continuar") }} </v-btn>
    </ValidationObserver>
    </div>
  </template>
    <script>

import NgEmail from "@/components/inputs/NgEmail.vue";
import NgText from "@/components/inputs/NgText.vue";
import NgPhone from "@/components/inputs/NgPhone.vue"
import NgSelect from "@/components/inputs/NgSelect.vue"
import NgDate from "@/components/inputs/NgDate.vue"
import NgAuto from "@/components/inputs/NgAuto.vue"
import NgInteger from "@/components/inputs/NgInteger.vue"
import NgTextarea from "@/components/inputs/NgTextarea.vue"
import NgNumber from "@/components/inputs/NgNumber.vue"
//import NgCheckbox from "@/components/inputs/NgCheckbox.vue"
import NgSelectOptions from "@/components/inputs/NgSelectOptions.vue"

  export default {
    name: "FormBasicData",
    components: { NgEmail, NgText, NgPhone, NgSelect, NgDate, NgAuto, NgInteger, NgTextarea, NgNumber,  NgSelectOptions },
    props: {
      form: {
          type: Object
      },
      dense: {
          type: Boolean,
          default: false
      },
      outlined: {
          type: Boolean,
          default: true
      },
      filled: {
          type: Boolean,
          default: false
      }
    },
    data: () => ({
  
      rules: {
        //id: { required: true },
        ciudad_residencia: { required: true },
        barrio: { required: true },
        dir: { required: true},
        celular: { required: true },
        telefono: { required: true},
        correo: { required: true, email: true },
      
      },
    }),
    created() {},
  
    methods: {
      save() {
        let data = {
          barrio: this.form.barrio,
          dir: this.form.dir,
          celular: this.form.celular,
          telefono: this.form.telefono,
          correo: this.form.correo,

        }
        if(this.form.ciudad_residencia){
          data.ciudad_residencia = this.form.ciudad_residencia.id
        }
        this.$http.post2('api/v2/customer/' + this.form.id, data).then((response) => {
          if (response.success) {
            this.$emit('next')
          }
        });
      },
    },
  };
  </script>
    