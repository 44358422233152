<template>
  <div>
    <ValidationObserver v-slot="{ invalid, errors }" ref="form">
      <v-row v-if="form" class="pt-4">
        <v-col cols="12" class="pb-2"
          ><span class="text-subtitle-2">Cuenta Principal</span></v-col
        >
        <v-col cols="12" md="6"
          ><ng-select
            v-model="form.banco1"
            label="bank.banco"
            url="api/v1/lists/bank"
            :rules="rules['banco1']"
            :dense="dense"
            :outlined="outlined"
            :filled="filled"
          ></ng-select
        ></v-col>

        <v-col cols="12" md="6"
          ><ng-select
            v-model="form.tipo_cuenta1"
            label="bank.tipo_cuenta"
            url="api/v1/lists/account-type"
            :rules="rules['tipo_cuenta1']"
            :dense="dense"
            :outlined="outlined"
            :filled="filled"
          ></ng-select
        ></v-col>

        <v-col cols="12" md="6"
          ><ng-integer
            v-model="form.numero1"
            label="bank.numero_cuenta"
            :rules="rules['numero1']"
            :dense="dense"
            :outlined="outlined"
            :filled="filled"
          ></ng-integer
        ></v-col>

        <v-col cols="12"> </v-col>

        <v-col cols="12" class="pb-2"
          ><span class="text-subtitle-2">Otra Cuenta (si la tiene)</span></v-col
        >
        <v-col cols="12" md="6"
          ><ng-select
            v-model="form.banco2"
            label="bank.banco2"
            url="api/v1/lists/bank"
            :rules="rules['banco2']"
            :dense="dense"
            :outlined="outlined"
            :filled="filled"
          ></ng-select
        ></v-col>

        <v-col cols="12" md="6"
          ><ng-select
            v-model="form.tipo_cuenta2"
            label="bank.tipo_cuenta2"
            url="api/v1/lists/account-type"
            :rules="rules['tipo_cuenta2']"
            :dense="dense"
            :outlined="outlined"
            :filled="filled"
          ></ng-select
        ></v-col>
        <v-col cols="12" md="6"
          ><ng-integer
            v-model="form.numero2"
            label="bank.numero_cuenta2"
            :rules="rules['numero2']"
            :dense="dense"
            :outlined="outlined"
            :filled="filled"
          ></ng-integer
        ></v-col>
      </v-row>

      <v-btn color="primary" class="mt-2" :disabled="invalid" @click="save">
        {{ $t("Continuar") }}
      </v-btn>
    </ValidationObserver>
  </div>
</template>
    <script>
import NgEmail from "@/components/inputs/NgEmail.vue";
import NgText from "@/components/inputs/NgText.vue";
import NgPhone from "@/components/inputs/NgPhone.vue";
import NgSelect from "@/components/inputs/NgSelect.vue";
import NgDate from "@/components/inputs/NgDate.vue";
import NgAuto from "@/components/inputs/NgAuto.vue";
import NgInteger from "@/components/inputs/NgInteger.vue";
import NgTextarea from "@/components/inputs/NgTextarea.vue";
// puedes importar los inputs desde components/inputs
export default {
  name: "FormBasicData",
  components: {
    NgEmail,
    NgText,
    NgPhone,
    NgSelect,
    NgDate,
    NgAuto,
    NgInteger,
    NgTextarea,
  },
  props: {
    form: {
      type: Object,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    filled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    rules: {
      //id: { required: true },
      numero1: { required: true },
      tipo_cuenta1: { required: true },
      banco1: { required: true },
      numero2: {},
      tipo_cuenta2: {},
      banco2: {},
    },
  }),
  created() {},

  methods: {
    save() {
      let data = {
        id: this.form.id,
        banco1: '',
        banco2: '',
        tipo_cuenta1: '',
        tipo_cuenta2: '',
        numero1: this.form.numero1,
        numero2: this.form.numero2,
        //representante_tipo: this.form.empresa_representante_tipo_doc
      };
      if (this.form.banco1) {
        data.banco1 = this.form.banco1.id;
      }
      if (this.form.banco2) {
        data.banco2 = this.form.banco2.id;
      }
      if (this.form.tipo_cuenta1) {
        data.tipo_cuenta1 = this.form.tipo_cuenta1.id;
      }
      if (this.form.tipo_cuenta2) {
        data.tipo_cuenta2 = this.form.tipo_cuenta2.id;
      }

      this.$http
        .post2("api/v2/bank-information/" + this.form.id, {
          bankInformation: data,
        })
        .then((response) => {
          if (response.success) {
            this.$emit("next");
          }
        });
    },
  },
};
</script>
    