<template>
    <div>
        <CustomerForm></CustomerForm>

    </div>
</template>
<script>

import CustomerForm from './CustomerForm.vue'
export default {
  name: "CustomerPage",
  components: { CustomerForm },
  data: () => ({
   
  }),
  created() {

  },
 
 
  methods: {
    
  },
};
</script>
