<template>
  <div>
    <ValidationObserver v-slot="{ invalid }" ref="form">
    <v-row  v-if="form" class="pt-4">

      <v-col cols="12" md="6" 
        ><ng-text
          v-model="form.nombre"
          label="person.nombre"
          :rules="rules['nombre']"
          :dense="dense"
          :outlined="outlined"
          :filled="filled"
        ></ng-text
      ></v-col>
      <v-col cols="12" md="6" 
        ><ng-text
          v-model="form.apellido"
          label="person.apellido"
          :rules="rules['apellido']"
          :dense="dense"
          :outlined="outlined"
          :filled="filled"
        ></ng-text
      ></v-col>
      
      <v-col cols="12" md="6" 
        ><ng-select
          v-model="form.tipo_documento"
          label="person.tipo_documento"
          url="api/v1/lists/document-type-person"
          :rules="rules['tipo_documento']"
          :dense="dense"
          :outlined="outlined"
          :filled="filled"
        ></ng-select
      ></v-col>
      <v-col cols="12" md="6" 
        ><ng-text
          v-model="form.documento"
          label="person.documento"
          :rules="rules['documento']"
          :dense="dense"
          :outlined="outlined"
          :filled="filled"
        ></ng-text
      ></v-col>
      <v-col cols="12" md="6" 
        ><ng-date
          v-model="form.fecha_expedicion"
          label="person.fecha_expedicion"
          :rules="rules['fecha_expedicion']"
          :dense="dense"
          :outlined="outlined"
          :filled="filled"
        ></ng-date
      ></v-col>

      <v-col cols="12" md="6" 
        ><ng-text
          v-model="form.ciudad_expedicion"
          label="person.ciudad_expedicion"
          :rules="rules['ciudad_expedicion']"
          :dense="dense"
          :outlined="outlined"
          :filled="filled"
        ></ng-text
      ></v-col>


      <v-col cols="12" md="6" 
        ><ng-date
          v-model="form.fecha_nacimiento"
          label="person.fecha_nacimiento"
          :rules="rules['fecha_nacimiento']"
          :dense="dense"
          :outlined="outlined"
          :filled="filled"
        ></ng-date
      ></v-col>

      <v-col cols="12" md="6" 
        ><ng-auto
          url="api/v1/lists/country"
          v-model="form.pais_nacimiento"
          label="person.pais_nacimiento"
          :rules="rules['pais_nacimiento']"
          :dense="dense"
          :outlined="outlined"
          :filled="filled"
        ></ng-auto
      ></v-col>

      <v-col cols="12" md="6" 
        ><ng-select
          v-model="form.tipo_genero"
          label="person.tipo_genero"
          url="api/v1/lists/gender"
          :rules="rules['tipo_genero']"
          :dense="dense"
          :outlined="outlined"
          :filled="filled"
        ></ng-select
      ></v-col>

      <v-col cols="12" md="6" 
        ><ng-select
          v-model="form.tipo_estado_civil"
          label="person.tipo_estado_civil"
          url="api/v1/lists/marital-status"
          :rules="rules['tipo_estado_civil']"
          :dense="dense"
          :outlined="outlined"
          :filled="filled"
        ></ng-select
      ></v-col>

      <v-col cols="12" md="6" 
        ><ng-select
          v-model="form.tipo_vivienda"
          label="person.tipo_vivienda"
          url="api/v1/lists/housing-type"
          :rules="rules['tipo_vivienda']"
          :dense="dense"
          :outlined="outlined"
          :filled="filled"
        ></ng-select
      ></v-col>

      <v-col cols="12" md="6" 
        ><ng-integer
          v-model="form.anhos_en_vivienda"
          label="person.anhos_en_vivienda"
          :rules="rules['anhos_en_vivienda']"
          :dense="dense"
          :outlined="outlined"
          :filled="filled"
        ></ng-integer
      ></v-col>

      <v-col cols="12" md="6" 
        ><ng-select
          v-model="form.tipo_nivel_academico"
          label="person.tipo_nivel_academico"
          url="api/v1/lists/academy-level"
          :rules="rules['tipo_nivel_academico']"
          :dense="dense"
          :outlined="outlined"
          :filled="filled"
        ></ng-select
      ></v-col>


      <v-col cols="12" md="6" 
        ><ng-text
          v-model="form.profesion"
          label="person.profesion"
          :rules="rules['profesion']"
          :dense="dense"
          :outlined="outlined"
          :filled="filled"
        ></ng-text
      ></v-col>


      <v-col cols="12" md="6" 
        ><ng-select
          v-model="form.tipo_ocupacion"
          label="person.tipo_ocupacion"
          url="api/v1/lists/occupation"
          :rules="rules['tipo_ocupacion']"
          :dense="dense"
          :outlined="outlined"
          :filled="filled"
        ></ng-select
      ></v-col>


      <v-col cols="12" md="6" 
        ><ng-textarea
          v-model="form.descripcion_ocupacion"
          label="person.descripcion_ocupacion"
          :rules="rules['descripcion_ocupacion']"
          :dense="dense"
          :outlined="outlined"
          :filled="filled"
        ></ng-textarea
      ></v-col>
      
    </v-row>
    

    <v-btn color="primary" class="mt-2" :disabled="invalid" @click="save"> {{ $t("Continuar") }} </v-btn>
  </ValidationObserver>
  </div>
</template>
  <script>

import NgEmail from "@/components/inputs/NgEmail.vue";
import NgText from "@/components/inputs/NgText.vue";
import NgPhone from "@/components/inputs/NgPhone.vue"
import NgSelect from "@/components/inputs/NgSelect.vue"
import NgDate from "@/components/inputs/NgDate.vue"
import NgAuto from "@/components/inputs/NgAuto.vue"
import NgInteger from "@/components/inputs/NgInteger.vue"
import NgTextarea from "@/components/inputs/NgTextarea.vue"


export default {
  name: "FormBasicData",
  components: { NgEmail, NgText, NgPhone, NgSelect, NgDate, NgAuto, NgInteger, NgTextarea},
  props: {
    form: {
        type: Object
    },
    dense: {
        type: Boolean,
        default: false
    },
    outlined: {
        type: Boolean,
        default: true
    },
    filled: {
        type: Boolean,
        default: false
    }
  },
  data: () => ({

    rules: {
      //id: { required: true },
      tipo_documento: { required: true },
      documento: { required: true },
      fecha_expedicion: { required: true},
      ciudad_expedicion: { required: true },
      nombre: { required: true},
      apellido: { required: true },

      fecha_nacimiento: { required: true },
      pais_nacimiento: { required: true },
      tipo_genero: { required: true },
      tipo_estado_civil: { required: true },
      tipo_nivel_academico: { required: true },
      tipo_ocupacion: { required: true },
      profesion: {  },
      tipo_vivienda: { required: true },
      descripcion_ocupacion: { required: true  },
      anhos_en_vivienda: { required: true  },
    
    },
  }),
  created() {},

  methods: {
    save(){
        //console.log(this.form)
        let data = {
          id: this.form.id,
          tipo_documento: '',
          documento: this.form.documento + '',
          fecha_expedicion: this.form.fecha_expedicion,
          ciudad_expedicion: this.form.ciudad_expedicion,
          nombre: this.form.nombre,
          apellido: this.form.apellido,
          fecha_nacimiento: this.form.fecha_nacimiento,
          pais_nacimiento: '',
          tipo_genero: '',
          tipo_estado_civil: '',
          tipo_nivel_academico: '',
          tipo_ocupacion: '',
          profesion: '',
          tipo_vivienda: '',
          descripcion_ocupacion: this.form.descripcion_ocupacion,
          anhos_en_vivienda: this.form.anhos_en_vivienda,
        }
        if(this.form.tipo_ocupacion){
          data.tipo_ocupacion = this.form.tipo_ocupacion.id
        }
        if(this.form.profesion){
          data.profesion = this.form.profesion.id
        }
        if(this.form.tipo_vivienda){
          data.tipo_vivienda = this.form.tipo_vivienda.id
        }
        if(this.form.tipo_documento){
          data.tipo_documento = this.form.tipo_documento.id
        }
        if(this.form.pais_nacimiento){
          data.pais_nacimiento = this.form.pais_nacimiento.id
        }
        if(this.form.tipo_genero){
          data.tipo_genero = this.form.tipo_genero.id
        }
        if(this.form.tipo_estado_civil){
          data.tipo_estado_civil = this.form.tipo_estado_civil.id
        }
        if(this.form.tipo_nivel_academico){
          data.tipo_nivel_academico = this.form.tipo_nivel_academico.id
        }
        if(this.form.ciudad){
          data.ciudad = this.form.ciudad.id
        }
        if(this.form.ciudad_residencia){
          data.ciudad_residencia = this.form.ciudad_residencia.id
        }
        if(this.form.tipo_estado_civil){
          data.tipo_estado_civil = this.form.tipo_estado_civil.id
        }
        if(this.form.tipo_cliente){
          data.tipo_cliente = this.form.tipo_cliente.id
        }
        if(this.form.representante_tipo){
          data.representante_tipo = this.form.representante_tipo.id
        }
        if(this.form.tipo_actividad_economica){
          data.tipo_actividad_economica = this.form.tipo_actividad_economica.id
        }
        if(this.form.tipo_clase_empresa){
          data.tipo_clase_empresa = this.form.tipo_clase_empresa.id
        }
        if(this.form.tipo_empresa){
          data.tipo_empresa = this.form.tipo_empresa.id
        }
        if(this.form.tipo_sociedad){
          data.tipo_sociedad = this.form.tipo_sociedad.id
        }
        if(this.form.tipo_sociedad){
          data.tipo_sociedad = this.form.tipo_sociedad.id
        }
        

        this.$http.post2('api/v2/customer/' + this.form.id, data).then((response) => {
          if (response.success) {
            this.$emit('next')
          }
        });
      }    
  },
};
</script>
  <style>

</style>