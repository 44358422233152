<template>
  <div>
    <ValidationObserver v-slot="{ invalid }" ref="form">
      <v-row v-if="form" class="pt-4">
        <v-col cols="12" md="6"
          ><ng-text
            v-model="form.nombre_empresa"
            label="person.nombre_empresa"
            :rules="rules['nombre_empresa']"
            :dense="dense"
            :outlined="outlined"
            :filled="filled"
          ></ng-text
        ></v-col>
        <v-col cols="12" md="6"
          ><ng-date
            v-model="form.fecha_ingreso_empresa"
            label="person.fecha_ingreso_empresa"
            :rules="rules['fecha_ingreso_empresa']"
            :dense="dense"
            :outlined="outlined"
            :filled="filled"
          ></ng-date
        ></v-col>

        <v-col cols="12" md="6"
          ><ng-text
            v-model="form.cargo"
            label="person.cargo"
            :rules="rules['cargo']"
            :dense="dense"
            :outlined="outlined"
            :filled="filled"
          ></ng-text
        ></v-col>
        <v-col cols="12" md="6"
          ><ng-text
            v-model="form.direccion_empresa"
            label="person.direccion_empresa"
            :rules="rules['direccion_empresa']"
            :dense="dense"
            :outlined="outlined"
            :filled="filled"
          ></ng-text
        ></v-col>
        <v-col cols="12" md="6"
          ><ng-phone
            v-model="form.telefono_empresa"
            label="person.telefono_empresa"
            :rules="rules['telefono_empresa']"
            :dense="dense"
            :outlined="outlined"
            :filled="filled"
          ></ng-phone
        ></v-col>

        <v-col cols="12" md="6"
          ><ng-text
            v-model="form.ciudad_empresa"
            label="person.ciudad_empresa"
            :rules="rules['ciudad_empresa']"
            :dense="dense"
            :outlined="outlined"
            :filled="filled"
          ></ng-text
        ></v-col>
      </v-row>

      <v-btn color="primary" class="mt-2" :disabled="invalid" @click="save">
        {{ $t("Continuar") }}
      </v-btn>
    </ValidationObserver>
  </div>
</template>
    <script>
import NgEmail from "@/components/inputs/NgEmail.vue";
import NgText from "@/components/inputs/NgText.vue";
import NgPhone from "@/components/inputs/NgPhone.vue";
import NgSelect from "@/components/inputs/NgSelect.vue";
import NgDate from "@/components/inputs/NgDate.vue";
import NgAuto from "@/components/inputs/NgAuto.vue";
import NgInteger from "@/components/inputs/NgInteger.vue";
import NgTextarea from "@/components/inputs/NgTextarea.vue";
import NgNumber from "@/components/inputs/NgNumber.vue";
//import NgCheckbox from "@/components/inputs/NgCheckbox.vue"
import NgSelectOptions from "@/components/inputs/NgSelectOptions.vue";
import NgMoney from "@/components/inputs/NgMoney.vue";
import NgSwitch from "@/components/inputs/NgSwitch.vue";

export default {
  name: "FormBasicData",
  components: {
    NgEmail,
    NgText,
    NgSwitch,
    NgPhone,
    NgSelect,
    NgDate,
    NgAuto,
    NgInteger,
    NgTextarea,
    NgNumber,
    NgSelectOptions,
    NgMoney,
  },
  props: {
    form: {
      type: Object,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    filled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    rules: {
      nombre_empresa: { required: true },
      fecha_ingreso_empresa: { required: true },
      cargo: { required: true },
      direccion_empresa: { required: true },
      telefono_empresa: { required: true },
      ciudad_empresa: { required: true },
    },
  }),
  created() {},

  methods: {
    save() {
      let data = {
        id: this.form.id,
        nombre_empresa: this.form.nombre_empresa,
        fecha_ingreso_empresa: this.form.fecha_ingreso_empresa,
        cargo: this.form.cargo,
        direccion_empresa: this.form.direccion_empresa,
        telefono_empresa: this.form.telefono_empresa,
        ciudad_empresa: this.form.ciudad_empresa,
      };
   

      this.$http
        .post2("api/v2/working-information/" + this.form.id, {
          workingInformation: data,
        })
        .then((response) => {
          if (response.success) {
            this.$emit("next");
          }
        });
    },
  },
};
</script>
    