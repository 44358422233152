<template>
    <div>
      <ValidationObserver v-slot="{ invalid }" ref="form">
      <v-row v-if="form" class="pt-4">
  
        <v-col cols="12" md="6" 
          ><ng-money
            v-model="form.ingresos"
            label="person.ingresos"
            :rules="rules['ingresos']"
            :dense="dense"
            :outlined="outlined"
            :filled="filled"
          ></ng-money
        ></v-col>
        <v-col cols="12" md="6" 
          ><ng-money
            v-model="form.otros_ingresos"
            label="person.otros_ingresos"
            :rules="rules['otros_ingresos']"
            :dense="dense"
            :outlined="outlined"
            :filled="filled"
          ></ng-money
        ></v-col>
        
        <v-col cols="12" md="12" 
          ><ng-textarea
            v-model="form.ingresos_desc"
            label="person.ingresos_desc"
            :rules="rules['ingresos_desc']"
            :dense="dense"
            :outlined="outlined"
            :filled="filled"
          ></ng-textarea
        ></v-col>
        <v-col cols="12" md="6" 
          ><ng-money
            v-model="form.total_ingresos"
            label="person.total_ingresos"
            :rules="rules['total_ingresos']"
            :dense="dense"
            :outlined="outlined"
            :filled="filled"
          ></ng-money
        ></v-col>
        <v-col cols="12" md="6" 
          ><ng-money
            v-model="form.total_egresos"
            label="person.total_egresos"
            :rules="rules['total_egresos']"
            :dense="dense"
            :outlined="outlined"
            :filled="filled"
          ></ng-money
        ></v-col>
  
        <v-col cols="12" md="6" 
          ><ng-money
            v-model="form.total_activos"
            label="person.total_activos"
            :rules="rules['total_activos']"
            :dense="dense"
            :outlined="outlined"
            :filled="filled"
          ></ng-money
        ></v-col>
        <v-col cols="12" md="6" 
          ><ng-money
            v-model="form.total_pasivos"
            label="person.total_pasivos"
            :rules="rules['total_pasivos']"
            :dense="dense"
            :outlined="outlined"
            :filled="filled"
          ></ng-money
        ></v-col>
        
      </v-row>
      
  
      <v-btn color="primary" class="mt-2" :disabled="invalid" @click="save"> {{ $t("Continuar") }} </v-btn>
    </ValidationObserver>
    </div>
  </template>
    <script>
import NgEmail from "@/components/inputs/NgEmail.vue";
import NgText from "@/components/inputs/NgText.vue";
import NgPhone from "@/components/inputs/NgPhone.vue"
import NgSelect from "@/components/inputs/NgSelect.vue"
import NgDate from "@/components/inputs/NgDate.vue"
import NgAuto from "@/components/inputs/NgAuto.vue"
import NgInteger from "@/components/inputs/NgInteger.vue"
import NgTextarea from "@/components/inputs/NgTextarea.vue"
import NgNumber from "@/components/inputs/NgNumber.vue"
//import NgCheckbox from "@/components/inputs/NgCheckbox.vue"
import NgSelectOptions from "@/components/inputs/NgSelectOptions.vue"
import NgMoney from "@/components/inputs/NgMoney.vue";
import NgSwitch from "@/components/inputs/NgSwitch.vue";

  export default {
    name: "FormBasicData",
    components: {NgEmail, NgText, NgPhone, NgSwitch, NgSelect, NgDate, NgAuto, NgInteger, NgTextarea, NgNumber,  NgSelectOptions, NgMoney},
    props: {
      form: {
          type: Object
      },
      dense: {
          type: Boolean,
          default: false
      },
      outlined: {
          type: Boolean,
          default: true
      },
      filled: {
          type: Boolean,
          default: false
      }
    },
    data: () => ({
  
      rules: {
        //id: { required: true },
        ingresos: { required: true },
        otros_ingresos: { required: true },
        ingresos_desc: { required: false },
        total_ingresos: { required: true },
        total_egresos: { required: true},
        total_activos: { required: true },  
        total_pasivos: { required: true }
      
      },
    }),
    created() {},
  
    methods: {
      save(){

        let data = {
          id: this.form.id,
          //ciudad: this.form.ciudad,
          ingresos: this.form.ingresos,
          otros_ingresos: this.form.otros_ingresos,
          ingresos_desc: this.form.ingresos_desc,
          total_ingresos: this.form.total_ingresos,
          total_egresos: this.form.total_egresos,
          total_activos: this.form.total_activos,
          total_pasivos: this.form.total_pasivos,
          
          //representante_tipo: this.form.empresa_representante_tipo_doc
          
        }
        /*if(this.form.ciudad){
          data.ciudad = this.form.ciudad.id
        }
        if(this.form.representante_tipo){
          data.representante_tipo = this.form.representante_tipo.id
        }*/
        

        this.$http.post2('api/v2/bank-information/' + this.form.id, { bankInformation: data }).then((response) => {
          if (response.success) {
            this.$emit('next')
          }
        });        
      }

    },
  };
  </script>
    