<template>
    <div>
      <ValidationObserver v-slot="{ invalid }" ref="form">
      <v-row  v-if="form" class="pt-4">
        <v-col cols="12" md="6" 
          ><ng-select
            v-model="form.tipo_documento"
            label="company.tipo_documento"
            url="api/v1/lists/document-type-company"
            :rules="rules['tipo_documento']"
            :dense="dense"
            :outlined="outlined"
            :filled="filled"
          ></ng-select
        ></v-col>
        <v-col cols="12" md="6" 
          ><ng-text
            v-model="form.documento"
            label="company.documento"
            :rules="rules['documento']"
            :dense="dense"
            :outlined="outlined"
            :filled="filled"
          ></ng-text
        ></v-col>

        <v-col cols="12" md="12" 
          ><ng-text
            v-model="form.nombre"
            label="company.nombre"
            :rules="rules['nombre']"
            :dense="dense"
            :outlined="outlined"
            :filled="filled"
          ></ng-text
        ></v-col>
        <v-col cols="12" md="6" 
          ><ng-select
            v-model="form.tipo_empresa"
            label="company.tipo_empresa"
            url="api/v1/lists/company-type"
            :rules="rules['tipo_empresa']"
            :dense="dense"
            :outlined="outlined"
            :filled="filled"
          ></ng-select
        ></v-col>

        <v-col cols="12" md="6" 
          ><ng-select
            v-model="form.tipo_actividad_economica"
            label="company.tipo_actividad_economica"
            url="api/v1/lists/activity-type"
            :rules="rules['tipo_actividad_economica']"
            :dense="dense"
            :outlined="outlined"
            :filled="filled"
          ></ng-select
        ></v-col>

        <v-col cols="12" md="6" 
          ><ng-text
            v-model="form.empresa_actividad"
            label="company.empresa_actividad"
            :rules="rules['empresa_actividad']"
            :dense="dense"
            :outlined="outlined"
            :filled="filled"
          ></ng-text
        ></v-col>

        <v-col cols="12" md="6" 
          ><ng-text
            v-model="form.empresa_ciiu"
            label="company.empresa_ciiu"
            :rules="rules['empresa_ciiu']"
            :dense="dense"
            :outlined="outlined"
            :filled="filled"
          ></ng-text
        ></v-col>

        <!--v-col cols="12" md="6" 
          ><ng-select
            v-model="form.tipo_sociedad"
            label="company.tipo_sociedad"
            url="api/v1/lists/society-type"
            :rules="rules['tipo_sociedad']"
            :dense="dense"
            :outlined="outlined"
            :filled="filled"
          ></ng-select
        ></v-col-->

        <!--v-col cols="12" md="6" 
          ><ng-select
            v-model="form.tipo_clase_empresa"
            label="company.tipo_clase_empresa"
            url="api/v1/lists/company-class-type"
            :rules="rules['tipo_clase_empresa']"
            :dense="dense"
            :outlined="outlined"
            :filled="filled"
          ></ng-select
        ></v-col-->


        
      
        <v-col cols="12" md="6" 
          ><ng-date
            v-model="form.empresa_fecha_constitucion"
            label="company.empresa_fecha_constitucion"
            :rules="rules['empresa_fecha_constitucion']"
            :dense="dense"
            :outlined="outlined"
            :filled="filled"
          ></ng-date
        ></v-col>
  
        <!--v-col cols="12" md="6" 
          ><ng-number
            v-model="form.empresa_numero_empleados"
            label="company.empresa_numero_empleados"
            :rules="rules['empresa_numero_empleados']"
            :dense="dense"
            :outlined="outlined"
            :filled="filled"
          ></ng-number
        ></v-col-->
  
  
       
        
      </v-row>
      <v-btn color="primary" class="mt-2" :disabled="invalid" @click="save"> {{ $t("Continuar") }} </v-btn>
    </ValidationObserver>
    </div>
  </template>
    <script>

import NgEmail from "@/components/inputs/NgEmail.vue";
import NgText from "@/components/inputs/NgText.vue";
import NgPhone from "@/components/inputs/NgPhone.vue"
import NgSelect from "@/components/inputs/NgSelect.vue"
import NgDate from "@/components/inputs/NgDate.vue"
import NgAuto from "@/components/inputs/NgAuto.vue"
import NgInteger from "@/components/inputs/NgInteger.vue"
import NgTextarea from "@/components/inputs/NgTextarea.vue"
import NgNumber from "@/components/inputs/NgNumber.vue"
//import NgCheckbox from "@/components/inputs/NgCheckbox.vue"
import NgSelectOptions from "@/components/inputs/NgSelectOptions.vue"
//import NgSelectOptionsMultiple from "@/components/inputs/NgSelectOptionsMultiple.vue"

  export default {
    name: "FormBasicData",
    components: { NgEmail, NgText, NgPhone, NgSelect, NgDate, NgAuto, NgInteger, NgTextarea, NgNumber,  NgSelectOptions },
    props: {
      form: {
          type: Object
      },
      dense: {
          type: Boolean,
          default: false
      },
      outlined: {
          type: Boolean,
          default: true
      },
      filled: {
          type: Boolean,
          default: false
      }
    },
    data: () => ({
  
      rules: {
        //id: { required: true },
        nombre: { required: true },
        tipo_documento: { required: true },
        documento: { required: true },
        empresa_fecha_constitucion: { required: true },
        tipo_entidad_oficial: { required: true },
        tipo_actividad_economica: { required: true },
        empresa_actividad: { required: true },
        empresa_ciiu: { required: true },
        tipo_sociedad: { required: true },
        tipo_clase_empresa: { required: true },
        empresa_numero_empleados: { required: true },
        tipo_empresa: { required: true }
        
      
      },
    }),
    created() {},
  
    methods: {

      save(){
        //console.log(this.form)
        let data = {
          id: this.form.id,
          nombre: this.form.nombre,
          tipo_documento: this.form.tipo_documento.id,
          documento: this.form.documento + '',
          empresa_fecha_constitucion: this.form.empresa_fecha_constitucion,
          empresa_actividad: this.form.empresa_actividad,
          empresa_ciiu: this.form.empresa_ciiu,
          empresa_numero_empleados: this.form.empresa_numero_empleados,
        }
        if(this.form.tipo_entidad_oficial){
          data.tipo_entidad_oficial = this.form.tipo_entidad_oficial.id
        }
        if(this.form.tipo_actividad_economica){
          data.tipo_actividad_economica = this.form.tipo_actividad_economica.id
        }
        if(this.form.tipo_sociedad){
          data.tipo_sociedad = this.form.tipo_sociedad.id
        }
        if(this.form.tipo_clase_empresa){
          data.tipo_clase_empresa = this.form.tipo_clase_empresa.id
        }
        if(this.form.tipo_empresa){
          data.tipo_empresa = this.form.tipo_empresa.id
        }

        this.$http.post2('api/v2/customer/' + this.form.id, data).then((response) => {
          if (response.success) {
            this.$emit('next')
          }
        });
      }
    },
  };
  </script>
    