<template>
  <div class="d-flex justify-center mt-4">
    <!--ValidationObserver v-slot="{ invalid, errors }" ref="form"></ValidationObserver-->
    <v-stepper v-model="e1" vertical style="max-width: 920px" :key="key">
      <!-- Tipo de cliente-->
      <v-stepper-step editable :complete="e1 > 1" step="1">{{
        $t("Tipo de Cliente")
      }}</v-stepper-step>
      <v-stepper-content step="1">
        <v-row no-gutters>
          <v-col cols="12" md="6" class="px-1">
            <ng-select-options
              v-model="form.tipo_cliente"
              :items="clientTypes"
            ></ng-select-options>
          </v-col>
        </v-row>
        <v-btn color="primary" class="mt-2" :disabled="!form.tipo_cliente" @click="e1++">
          {{ $t("Continuar") }}
        </v-btn>
      </v-stepper-content>

      <!-- Información general -->
      <v-stepper-step editable :complete="e1 > 2" step="2">{{
        $t("Información General")
      }}</v-stepper-step>
      <v-stepper-content step="2">
        <FormBasicDataPerson
          v-if="tipoClienteEsNatural()"
          :form="form"
          :dense="dense"
          :outlined="outlined"
          :filled="filled"
          @next="e1++"
        ></FormBasicDataPerson>

        <FormBasicDataCompany
          v-else
          :form="form"
          :dense="dense"
          :outlined="outlined"
          :filled="filled"
          @next="e1++"
        ></FormBasicDataCompany>
      </v-stepper-content>

      <!-- Información de contacto -->
      <v-stepper-step editable :complete="e1 > 3" step="3">{{
        $t("Información de Contacto")
      }}</v-stepper-step>
      <v-stepper-content step="3">
        <FormBasicContactPerson
          :form="form"
          :dense="dense"
          :outlined="outlined"
          :filled="filled"
          @next="e1++"
          v-if="tipoClienteEsNatural()"
        ></FormBasicContactPerson>

        <FormBasicContactCompany
          :form="form"
          :dense="dense"
          :outlined="outlined"
          :filled="filled"
          @next="e1++"
          v-else
        ></FormBasicContactCompany> 
      </v-stepper-content>

      <!-- Información..... -->
      <!--:complete="isOkay"-->
      <v-stepper-step editable step="4">Información Financiera</v-stepper-step>
      <v-stepper-content step="4">
       <FormFinancialInformationPerson v-if="tipoClienteEsNatural()"
          :form="form.bankInformation"
          :dense="dense"
          :outlined="outlined"
          :filled="filled"
          @next="e1++"
        ></FormFinancialInformationPerson>

        <FormFinancialInformation v-else
          :form="form.bankInformation"
          :dense="dense"
          :outlined="outlined"
          :filled="filled"
          @next="e1++"
        ></FormFinancialInformation>
      </v-stepper-content>

      <v-stepper-step editable step="5">
        <span v-if="tipoClienteEsNatural()">Información Laboral</span>
        <span v-else>Composición Accionaria de la Empresa</span>
      </v-stepper-step>

      <v-stepper-content step="5">
        <FormWorkingInformation v-if="tipoClienteEsNatural()"
          :form="form.workingInformation"
          :dense="dense"
          :outlined="outlined"
          :filled="filled"
          @next="e1++"
        ></FormWorkingInformation>

 
        <FormShareholdingStructure v-else
          :form="form"
          :dense="dense"
          :outlined="outlined"
          :filled="filled"
          @next="e1++"
        ></FormShareholdingStructure>
      </v-stepper-content>

      <v-stepper-step editable step="6">Cuentas Bancarías</v-stepper-step>
      <v-stepper-content step="6">
         <FormBankAccounts
          :form="form.bankInformation"
          :dense="dense"
          :outlined="outlined"
          :filled="filled"
          @next="e1++"
        ></FormBankAccounts>
      </v-stepper-content>
    </v-stepper>
  </div>
</template>
<script>
import NgSelectOptions from "@/components/inputs/NgSelectOptions.vue";
import FormBasicDataPerson from "./FormBasicDataPerson.vue";
import FormBasicContactPerson from "./FormBasicContactPerson.vue";
import FormBasicDataCompany from "./FormBasicDataCompany.vue";
import FormBasicContactCompany from "./FormBasicContactCompany.vue";
import FormFinancialInformation from "./FormFinancialInformation.vue";
import FormFinancialInformationPerson from "./FormFinancialInformationPerson.vue";
import FormBankAccounts from "./FormBankAccounts.vue";
import FormWorkingInformation from "./FormWorkingInformation.vue";
import FormShareholdingStructure from "./FormShareholdingStructure.vue";

export default {
  name: "CustomerForm",
  components: {
    NgSelectOptions,
    FormBasicDataPerson,
    FormBasicDataCompany,
    FormFinancialInformation,
    FormBasicContactPerson,
    FormBasicContactCompany,
    FormBankAccounts,
    FormWorkingInformation,
    FormFinancialInformationPerson,
    FormShareholdingStructure,
  },
  data: () => ({
    e1: 1,
    key: 0,
    dense: true,
    outlined: true,
    filled: false,
    switch1: false,
    loading: false,
    form: { tipo_cliente: { id: "natural" } },
    clientTypes: [
      { id: "natural", name: "Persona Natural" },
      { id: "juridica", name: "Persona Juridica" },
    ],
  }),
  created() {
    this.form.tipo_cliente = this.clientTypes[0];
  },
  async mounted(){
    let item = await this.getItem();
    //console.log(item)
    
    this.setFormValues(item);
  
    
  },
  methods: {
    setFormValues(item){
        /*this.form.id = item.id;
        this.form.nombre = item.nombre;
        this.form.tipo_documento  = item.tipo_documento;
        this.form.documento = item.documento;
        this.form.empresa_fecha_constitucion  = item.empresa_fecha_constitucion;
        this.form.bus_tipo_entidad_oficial_id = item.bus_tipo_entidad_oficial_id;
        this.form.bus_tipo_actividad_economica_id   = item.bus_tipo_actividad_economica_id;
        this.form.empresa_actividad = item.empresa_actividad;
        this.form.empresa_ciiu = item.empresa_ciiu;
        this.form.bus_tipo_sociedad_id = item.bus_tipo_sociedad_id;
        this.form.bus_tipo_clase_empresa_id = item.bus_tipo_clase_empresa_id;
        this.form.empresa_numero_empleados = item.empresa_numero_empleados;
        this.form.bus_tipo_empresa_id = item.bus_tipo_empresa_id;

        this.form.ciudad = item.ciudad,
        this.form.barrio = item.barrio,
        this.form.dir = item.dir,
        this.form.celular = item.celular,
        this.form.telefono = item.telefono,
        this.form.correo = item.correo,
        this.form.suc1_ext = item.suc1_ext,
        this.form.empresa_representante_nombre = item.empresa_representante_nombre,
        this.form.empresa_representante_numero_documento= item.empresa_representante_numero_documento,
        this.form.empresa_representante_tipo_doc= item.empresa_representante_tipo_doc,*/
        if(item && item.bankInformation && !item.bankInformation.gastos){
          item.bankInformation.gastos = []
        }
        this.form = item;
        


        this.key = this.key + 1;
    },
    getItem() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        this.$http
          .get2('api/v2/customer-profile/1')
          .then((data) => {
            this.loading = false;
            resolve(data.item);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    tipoClienteEsNatural() {
      if (this.form.tipo_cliente && this.form.tipo_cliente.id == "natural") {
        return true;
      }
      return false;
    },
  },
};
</script>
