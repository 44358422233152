<template>
    <div>
      <ValidationObserver v-slot="{ invalid }" ref="form">
      <v-row v-if="form" class="pt-4">
        <v-col cols="12" md="6" 
          ><ng-auto
            v-model="form.ciudad"
            label="company.ciudad_empresa"
            url="api/v1/lists/city"
            :rules="rules['ciudad']"
            :dense="dense"
            :outlined="outlined"
            :filled="filled"
          ></ng-auto
        ></v-col>

        <v-col cols="12" md="6" 
          ><ng-text
            v-model="form.barrio"
            label="company.barrio"
            :rules="rules['barrio']"
            :dense="dense"
            :outlined="outlined"
            :filled="filled"
          ></ng-text
        ></v-col>
        <v-col cols="12" md="6" 
          ><ng-text
            v-model="form.dir"
            label="company.dir"
            :rules="rules['dir']"
            :dense="dense"
            :outlined="outlined"
            :filled="filled"
          ></ng-text
        ></v-col>
        

        <v-col cols="12" md="6" 
          ><ng-phone
            v-model="form.celular"
            label="company.celular"
            :rules="rules['celular']"
            :dense="dense"
            :outlined="outlined"
            :filled="filled"
          ></ng-phone
        ></v-col>


        <v-col cols="12" md="6" 
          ><ng-phone
            v-model="form.telefono"
            label="company.telefono"
            :rules="rules['telefono']"
            :dense="dense"
            :outlined="outlined"
            :filled="filled"
          ></ng-phone
        ></v-col>

        <v-col cols="12" md="6" 
          ><ng-email
            v-model="form.correo"
            label="company.correo"
            :rules="rules['correo']"
            :dense="dense"
            :outlined="outlined"
            :filled="filled"
          ></ng-email
        ></v-col>


        <v-col cols="12" md="6" 
        ><ng-select
          v-model="form.representante_tipo"
          label="company.empresa_representante_tipo_doc"
          url="api/v1/lists/document-type-person"
          :rules="rules['representante_tipo']"
          :dense="dense"
          :outlined="outlined"
          :filled="filled"
        ></ng-select
      ></v-col>
      <v-col cols="12" md="6" 
        ><ng-text
          v-model="form.empresa_representante_numero_documento"
          label="company.empresa_representante_numero_documento"
          :rules="rules['empresa_representante_numero_documento']"
          :dense="dense"
          :outlined="outlined"
          :filled="filled"
        ></ng-text
      ></v-col>

      <v-col cols="12" md="6" 
        ><ng-text
          v-model="form.empresa_representante_nombre"
          label="company.empresa_representante_nombre"
          :rules="rules['empresa_representante_nombre']"
          :dense="dense"
          :outlined="outlined"
          :filled="filled"
        ></ng-text
      ></v-col>

      </v-row>
      
  
      <v-btn color="primary" class="mt-2" :disabled="invalid" @click="save"> {{ $t("Continuar") }} </v-btn>
    </ValidationObserver>
    </div>
  </template>
    <script>

import NgEmail from "@/components/inputs/NgEmail.vue";
import NgText from "@/components/inputs/NgText.vue";
import NgPhone from "@/components/inputs/NgPhone.vue"
import NgSelect from "@/components/inputs/NgSelect.vue"
import NgDate from "@/components/inputs/NgDate.vue"
import NgAuto from "@/components/inputs/NgAuto.vue"
import NgInteger from "@/components/inputs/NgInteger.vue"
import NgTextarea from "@/components/inputs/NgTextarea.vue"
import NgNumber from "@/components/inputs/NgNumber.vue"
//import NgCheckbox from "@/components/inputs/NgCheckbox.vue"
import NgSelectOptions from "@/components/inputs/NgSelectOptions.vue"

  export default {
    name: "FormBasicData",
    components: { NgEmail, NgText, NgPhone, NgSelect, NgDate, NgAuto, NgInteger, NgTextarea, NgNumber,  NgSelectOptions },
    props: {
      form: {
          type: Object
      },
      dense: {
          type: Boolean,
          default: false
      },
      outlined: {
          type: Boolean,
          default: true
      },
      filled: {
          type: Boolean,
          default: false
      }
    },
    data: () => ({
  
      rules: {
        //id: { required: true },
        ciudad: { required: true },
        barrio: { required: true },
        dir: { required: true},
        celular: { required: true },
        telefono: { required: true },
        correo: { required: true, email: true },
        suc1_ext: { },
        empresa_representante_nombre: { required: true },
        empresa_representante_numero_documento: { required: true },
        representante_tipo: { required: true },
      
      },
    }),
    created() {},
  
    methods: {
      save(){
        let data = {
          id: this.form.id,
          //ciudad: this.form.ciudad,
          barrio: this.form.barrio,
          dir: this.form.dir,
          celular: this.form.celular,
          telefono: this.form.telefono,
          correo: this.form.correo,
          suc1_ext: this.form.suc1_ext,
          empresa_representante_nombre: this.form.empresa_representante_nombre,
          empresa_representante_numero_documento: this.form.empresa_representante_numero_documento,
          //representante_tipo: this.form.empresa_representante_tipo_doc
          
        }
        if(this.form.ciudad){
          data.ciudad = this.form.ciudad.id
        }
        if(this.form.representante_tipo){
          data.representante_tipo = this.form.representante_tipo.id
        }
        

        this.$http.post2('api/v2/customer/' + this.form.id, data).then((response) => {
          if (response.success) {
            this.$emit('next')
          }
        });        
      }
    },
  };
  </script>
    