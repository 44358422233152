<template>
  <div class="my-2">
    <!--ValidationObserver v-slot="{ invalid }" ref="form"-->
        <b>¿Cuánto destinas mensalmente a cada uno de los siguientes gastos?</b>
      <v-row
        no-gutters
        class="pt-4"
        v-for="(item, index) in items"
        :key="index"
      >
        <v-col cols="4" md="4" class="px-1">
          <div class="text-subtitle-2">{{ item.name }}</div>
        </v-col>
        <v-col cols="8" md="8" class="px-1"
          ><ng-money
            v-model="item.amount"
            label="Total"
            :rules="rules['amount']"
            :dense="dense"
            :outlined="outlined"
            :filled="filled"
            @input="change"
          ></ng-money
        ></v-col>
      </v-row>

      <!--b v-if="invalid">Debe diligenciar todos los campos</b-->

    <!--/ValidationObserver-->
  </div>
</template>
    <script>
import NgEmail from "@/components/inputs/NgEmail.vue";
import NgText from "@/components/inputs/NgText.vue";
import NgPhone from "@/components/inputs/NgPhone.vue"
import NgSelect from "@/components/inputs/NgSelect.vue"
import NgDate from "@/components/inputs/NgDate.vue"
import NgAuto from "@/components/inputs/NgAuto.vue"
import NgInteger from "@/components/inputs/NgInteger.vue"
import NgTextarea from "@/components/inputs/NgTextarea.vue"
import NgNumber from "@/components/inputs/NgNumber.vue"
//import NgCheckbox from "@/components/inputs/NgCheckbox.vue"
import NgSelectOptions from "@/components/inputs/NgSelectOptions.vue"
import NgMoney from "@/components/inputs/NgMoney.vue";
import NgSwitch from "@/components/inputs/NgSwitch.vue";

export default {
  name: "FormTypesOfSpending",
  components: {  NgEmail, NgText, NgSwitch, NgPhone, NgSelect, NgDate, NgAuto, NgInteger, NgTextarea, NgNumber,  NgSelectOptions, NgMoney },
  props: {
    form: {
      type: Array,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    filled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    items: [],
    rules: {
      amount: { required: true },
    },
  }),
  created() {},
  mounted(){
    console.log(this.form)
    if(this.form && this.form.length > 0){
   
        this.items = JSON.parse(JSON.stringify(this.form));
    }else{
        this.initExpenses()
    }
  },

  methods: {
    change(){
        this.$emit('change', this.items)
    },
    initExpenses(){
        this.$http.get2('api/v1/lists/expenses-type', {}).then((response) => {
            if(response.success){
                for(const e of response.items){
                    e.amount = 0;
                }
                this.items = response.items;
                //this.form = this.items;
            }
        })
    }
  },
};
</script>
    